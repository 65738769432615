@font-face {
  font-family: Spectrum Icons;
  src: url('/assets/fonts/sb-icons.woff') format('woff'),
    url('/assets/fonts/sb-icons.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
.si {
  display: inline-block;
  font-family: Spectrum Icons !important;
  speak: none;
}
.si-version:before {
  content: 'v';
}
.si-bolt-f:before {
  content: '\E500';
}
.si-bolt:before {
  content: '\E501';
}
.si-youtube-f:before {
  content: '\E502';
}
.si-youtube:before {
  content: '\E503';
}
.si-arrow-down-f:before {
  content: '\E504';
}
.si-arrow-down-left-f:before {
  content: '\E505';
}
.si-arrow-down-left:before {
  content: '\E506';
}
.si-arrow-down-right-f:before {
  content: '\E507';
}
.si-arrow-down-right:before {
  content: '\E508';
}
.si-arrow-down:before {
  content: '\E509';
}
.si-arrow-left-f:before {
  content: '\E50A';
}
.si-arrow-left:before {
  content: '\E50B';
}
.si-arrow-right-f:before {
  content: '\E50C';
}
.si-arrow-right:before {
  content: '\E50D';
}
.si-arrow-up-f:before {
  content: '\E50E';
}
.si-arrow-up-left-f:before {
  content: '\E50F';
}
.si-arrow-up-left:before {
  content: '\E510';
}
.si-arrow-up-right-f:before {
  content: '\E511';
}
.si-arrow-up-right:before {
  content: '\E512';
}
.si-arrow-up:before {
  content: '\E513';
}
.si-backspace-f:before {
  content: '\E514';
}
.si-backspace:before {
  content: '\E515';
}
.si-camera:before {
  content: '\E520';
}
.si-favorites:before {
  content: '\E521';
}
.si-circle-cancel-f:before {
  content: '\E538';
}
.si-circle-cancel:before {
  content: '\E539';
}
.si-circle-caution-f:before {
  content: '\E53A';
}
.si-circle-caution:before {
  content: '\E53B';
}
.si-circle-minus-f:before {
  content: '\E53E';
}
.si-circle-minus:before {
  content: '\E53F';
}
.si-cloud-download-f:before {
  content: '\E544';
}
.si-cloud-download:before {
  content: '\E545';
}
.si-collapse-f:before {
  content: '\E54A';
}
.si-collapse:before {
  content: '\E54B';
}
.si-compose-f:before {
  content: '\E54C';
}
.si-compose:before {
  content: '\E54D';
}
.si-expand-f:before {
  content: '\E555';
}
.si-expand:before {
  content: '\E556';
}
.si-filter-f:before {
  content: '\E557';
}
.si-filter:before {
  content: '\E558';
}
.si-key-f:before {
  content: '\E55E';
}
.si-key:before {
  content: '\E55F';
}
.si-keypad-f:before {
  content: '\E560';
}
.si-keypad:before {
  content: '\E561';
}
.si-unlock-f:before {
  content: '\E564';
}
.si-unlock:before {
  content: '\E565';
}
.si-user-remove-f:before {
  content: '\E570';
}
.si-user-remove:before {
  content: '\E571';
}
.si-minus-f:before {
  content: '\E574';
}
.si-minus:before {
  content: '\E575';
}
.si-plus-f:before {
  content: '\E57B';
}
.si-plus:before {
  content: '\E57C';
}
.si-reminder-f:before {
  content: '\E57F';
}
.si-reminder:before {
  content: '\E580';
}
.si-settings:before {
  content: '\E584';
}
.si-speaker-f:before {
  content: '\E587';
}
.si-speaker-mute-f:before {
  content: '\E588';
}
.si-speaker-mute:before {
  content: '\E589';
}
.si-speaker-sound-f:before {
  content: '\E58A';
}
.si-speaker-sound:before {
  content: '\E58B';
}
.si-speaker:before {
  content: '\E58C';
}
.si-star-f:before {
  content: '\E58D';
}
.si-star:before {
  content: '\E58E';
}
.si-support-f:before {
  content: '\E591';
}
.si-support-phone:before {
  content: '\E593';
}
.si-support:before {
  content: '\E594';
}
.si-swap-f:before {
  content: '\E595';
}
.si-swap:before {
  content: '\E596';
}
.si-wifi-f:before {
  content: '\E59B';
}
.si-wifi:before {
  content: '\E59C';
}
.si-radio:before {
  content: '\E59F';
}
.si-radio-f:before {
  content: '\E5A0';
}
.si-user-add-f:before {
  content: '\E600';
}
.si-chevron-left:before {
  content: '\E601';
}
.si-bill:before {
  content: '\E602';
}
.si-uni:before {
  content: '\E603';
}
.si-phone-large:before {
  content: '\E604';
}
.si-chevron-right:before {
  content: '\E605';
}
.si-call-incoming:before {
  content: '\E606';
}
.si-call-incoming-f:before {
  content: '\E607';
}
.si-call-outgoing-f:before {
  content: '\E608';
}
.si-call-outgoing:before {
  content: '\E609';
}
.si-pause:before {
  content: '\E60A';
}
.si-pause-f:before {
  content: '\E60B';
}
.si-voice-settings:before {
  content: '\E60C';
}
.si-play:before {
  content: '\E60D';
}
.si-play-f:before {
  content: '\E60E';
}
.si-trash:before {
  content: '\E60F';
}
.si-trash-f:before {
  content: '\E610';
}
.si-tape:before {
  content: '\E611';
}
.si-tape-f:before {
  content: '\E612';
}
.si-person:before {
  content: '\E613';
}
.si-call-conference:before {
  content: '\E614';
}
.si-cloud-upload:before {
  content: '\E615';
}
.si-briefcase:before {
  content: '\E616';
}
.si-uni-f:before {
  content: '\E617';
}
.si-lock-f:before {
  content: '\E618';
}
.si-domain:before {
  content: '\E619';
}
.si-fax-f:before {
  content: '\E61A';
}
.si-hold-music-f:before {
  content: '\E61B';
}
.si-hosting:before {
  content: '\E61C';
}
.si-profile-card:before {
  content: '\E61D';
}
.si-internet-settings:before {
  content: '\E61E';
}
.si-internet:before {
  content: '\E61F';
}
.si-pin:before {
  content: '\E620';
}
.si-pin-f:before {
  content: '\E621';
}
.si-map-point:before {
  content: '\E622';
}
.si-map-point-f:before {
  content: '\E623';
}
.si-mail:before {
  content: '\E624';
}
.si-mail-f:before {
  content: '\E625';
}
.si-users:before {
  content: '\E626';
}
.si-dots-3-vert-f:before {
  content: '\E627';
}
.si-pdf:before {
  content: '\E628';
}
.si-pdf-f:before {
  content: '\E629';
}
.si-share:before {
  content: '\E62A';
}
.si-phone:before {
  content: '\E62B';
}
.si-phone-f:before {
  content: '\E62C';
}
.si-print-f:before {
  content: '\E62D';
}
.si-print:before {
  content: '\E62E';
}
.si-record:before {
  content: '\E62F';
}
.si-record-f:before {
  content: '\E630';
}
.si-save:before {
  content: '\E631';
}
.si-save-f:before {
  content: '\E632';
}
.si-notes-f:before {
  content: '\E633';
}
.si-document-f:before {
  content: '\E634';
}
.si-support-phone-f:before {
  content: '\E635';
}
.si-tv:before {
  content: '\E636';
}
.si-call-transfer:before {
  content: '\E637';
}
.si-call-transfer-f:before {
  content: '\E638';
}
.si-download:before {
  content: '\E639';
}
.si-download-f:before {
  content: '\E63A';
}
.si-circle-question:before {
  content: '\E63B';
}
.si-chevron-up:before {
  content: '\E63C';
}
.si-chevron-down:before {
  content: '\E63D';
}
.si-chevron-left-f:before {
  content: '\E63E';
}
.si-chevron-right-f:before {
  content: '\E63F';
}
.si-chevron-up-f:before {
  content: '\E640';
}
.si-chevron-down-f:before {
  content: '\E641';
}
.si-x:before {
  content: '\E642';
}
.si-search:before {
  content: '\E643';
}
.si-search-f:before {
  content: '\E644';
}
.si-company-card:before {
  content: '\E645';
}
.si-phone-card:before {
  content: '\E646';
}
.si-x-f:before {
  content: '\E647';
}
.si-record-f:before {
  content: '\E648';
}
.si-person-f:before {
  content: '\E649';
}
.si-circle-plus-f:before {
  content: '\E64A';
}
.si-checkbox:before {
  content: '\E64B';
}
.si-lock-f:before {
  content: '\E64C';
}
.si-sort:before {
  content: '\E64D';
}
.si-sort-f:before {
  content: '\E64E';
}
.si-caution-f:before {
  content: '\E64F';
}
.si-popout:before {
  content: '\E650';
}
.si-stop:before {
  content: '\E651';
}
.si-stop-f:before {
  content: '\E652';
}
.si-dots-3-horz-f:before {
  content: '\E653';
}
.si-share-f:before {
  content: '\E654';
}
.si-square-checkbox-f:before {
  content: '\E655';
}
.si-square-checkbox:before {
  content: '\E656';
}
.si-barbell:before {
  content: '\E657';
}
.si-home:before {
  content: '\E658';
}
.si-2x2-grid:before {
  content: '\E659';
}
.si-barbell-f:before {
  content: '\E65A';
}
.si-3x3-grid-f:before {
  content: '\E65B';
}
.si-radio:before {
  content: '\E65C';
}
.si-radio-f:before {
  content: '\E65D';
}
.si-circle-question-f:before {
  content: '\E65E';
}
.si-autopay:before {
  content: '\E65F';
}
.si-autopay-f:before {
  content: '\E660';
}
.si-checkmark:before {
  content: '\E661';
}
.si-dumbell:before {
  content: '\E662';
}
.si-circle-info-f:before {
  content: '\E663';
}
.si-circle-info:before {
  content: '\E664';
}
.si-checkbox-alt:before {
  content: '\E665';
}
.si-checkmark-f:before {
  content: '\E666';
}
.si-checkbox-alt-f:before {
  content: '\E667';
}
.si-notification-flag:before {
  content: '\E668';
}
.si-settings-card:before {
  content: '\E669';
}
.si-charter:before {
  content: '\E66A';
}
.si-cloud-apps:before {
  content: '\E66B';
}
.si-cloud-apps-f:before {
  content: '\E66C';
}
.si-dumbell-f:before {
  content: '\E66D';
}
.si-more-f:before {
  content: '\E66E';
}
.si-more:before {
  content: '\E66F';
}
.si-more-up-f:before {
  content: '\E670';
}
.si-more-up:before {
  content: '\E671';
}
.si-hold-music:before {
  content: '\E67A';
}
.si-racks-f:before {
  content: '\E67B';
}
.si-refresh:before {
  content: '\E67C';
}
.si-pagination-f:before {
  content: '\E800';
}
.si-bacon-f:before {
  content: '\E802';
}
.si-bacon:before {
  content: '\E803';
}
.si-layers-f:before {
  content: '\E804';
}
.si-layers:before {
  content: '\E805';
}
.si-pagination:before {
  content: '\E806';
}
.si-camera-f:before {
  content: '\E900';
}
.si-favorites-f:before {
  content: '\E901';
}
.si-user-add:before {
  content: '\E902';
}
.si-biz-phone:before {
  content: '\E903';
}
.si-voice-settings-f:before {
  content: '\E904';
}
.si-profile-card-f:before {
  content: '\E905';
}
.si-call-conference-f:before {
  content: '\E906';
}
.si-cloud-upload-f:before {
  content: '\E907';
}
.si-briefcase-f:before {
  content: '\E908';
}
.si-fax:before {
  content: '\E909';
}
.si-racks-f:before {
  content: '\E90A';
}
.si-users-f:before {
  content: '\E90B';
}
.si-dots-3-vert:before {
  content: '\E90C';
}
.si-tv-f:before {
  content: '\E90D';
}
.si-company-card-f:before {
  content: '\E90E';
}
.si-phone-card-f:before {
  content: '\E90F';
}
.si-circle-plus:before {
  content: '\E910';
}
.si-caution:before {
  content: '\E911';
}
.si-dots-3-horz:before {
  content: '\E912';
}
.si-home-f:before {
  content: '\E913';
}
.si-2x2-grid-f:before {
  content: '\E914';
}
.si-3x3-grid:before {
  content: '\E915';
}
.si-notification-flag-f:before {
  content: '\E916';
}
.si-user-settings:before {
  content: '\E917';
}
.si-settings-card-f:before {
  content: '\E918';
}
.si-calendar:before {
  content: '\E919';
}
.si-menu:before {
  content: '\E91A';
}
.si-laptop-wifi:before {
  content: '\E920';
}
.si-spectrum:before {
  content: '\E921';
}
.si-speech-bubble:before {
  content: '\E922';
}
.si-thumb-up-f:before {
  content: '\E923';
}
.si-thumb-down-f:before {
  content: '\E924';
}
.si-thumb-up:before {
  content: '\E925';
}
.si-thumb-down:before {
  content: '\E926';
}
.si-calendar:before {
  content: '\E953';
}
.si-settings-f:before {
  content: '\E954';
}
.si-edit-f:before {
  content: '\E962';
}
.si-discover:before {
  content: '\E963';
}
.si-amex:before {
  content: '\E964';
}
.si-leaf:before {
  content: '\E965';
}
.si-credit-card-f:before {
  content: '\E966';
}
.si-mastercard:before {
  content: '\E967';
}
.si-visa:before {
  content: '\E968';
}
.si-card-error:before {
  content: '\E969';
}
.si-spinner:before {
  content: '\E97B';
}
.si-menu:before {
  content: '\E9BD';
}
.si-facebook:before {
  content: '\EA8C';
}
.si-facebook-f:before {
  content: '\EA8D';
}
.si-twitter:before {
  content: '\EA91';
}
.si-twitter-f:before {
  content: '\EA92';
}
.si-linkedin-f:before {
  content: '\EAC8';
}
.si-linkedin:before {
  content: '\EAC9';
}
.si-bill:before {
  content: '\EB01';
}
.si-wrench:before {
  content: '\EB02';
}
.si-user-settings-f:before {
  content: '\EB03';
}
.si-profile:before {
  content: '\EB04';
}
.si-company:before {
  content: '\EB05';
}
.si-autopay:before {
  content: '\EB06';
}
.si-speech-bubble-f:before {
  content: '\EB07';
}
.si-leaf-circle:before {
  content: '\E970';
}
.si-leaf-prohibited:before {
  content: '\E971';
}
.si-eye:before {
  content: '\E972';
}
.si-eye-closed:before {
  content: '\E973';
}
.si-dropdown:before {
  content: '\E974';
}
